.skills--section {
   display: flex;
   max-width: 1500px;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   gap: 4rem;
}

.skills--section--container {
   display: grid;
   justify-content: center;
   align-items: flex-start;
   gap: 42.6px;
   grid-template-columns: repeat(2, 1fr);
}

.skills--section--card {
   display: flex;
   padding: 32px;
   flex-direction: column;
   align-items: flex-start;
   gap: 32px;
   flex: 1 0 0;
   border-radius: 10.6px;
   min-height: 250px;
   background: var(--card);
   border-bottom: 4px solid transparent;
   box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
   align-self: stretch;
}

.skills--section--card:hover {
   border-bottom: 4px solid var(--primary);
}

.skills--section--card:hover .skills--section--description {
   color: var(--darkblue);
}

.skills--section--img {
   display: flex;
   padding: 13.3px;
   justify-content: center;
   align-items: center;
   gap: 13.3px;
   border-radius: 10.6px;
   background-color: var(--card);
   box-shadow: none;
   font-size: 80px;
}

.skills--section--card--content {
   display: grid;
   gap: 32px;
}

.skills--section--title {
   color: var(--heading-color);
   font-size: var(--p-2);
   font-weight: 700;
   line-height: 45px;
}

.skills--section--description {
   color: var(--black);
   font-size: var(--p-text);
   font-style: normal;
   font-weight: 400;
   line-height: 32px;
}

.skills--section--skills {
   display: flex;
   justify-content: center;
   column-gap: 7rem;
}

.skills--group {
   min-width: 12rem;
   align-items: flex-start;
   row-gap: 1rem;
}

.skills--data {
   display: flex;
   align-items: center;
   column-gap: 1.5rem;
   margin-bottom: 0.5rem;
}

.skill--name {
   font-size: var(--p-15);
   text-align: left;
}

@media only screen and (max-width: 1430px) {
   .skills--section--skills {
      column-gap: 3rem;
   }
}

@media only screen and (max-width: 1200px) {
   .skills--section--container {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
   }

   .skills--section {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 0;
      padding-bottom: 0;
   }

   .skills--section--card {
      align-items: center;
   }
}

@media only screen and (max-width: 566px) {
   .skills--section--skills {
      flex-direction: column;
      align-items: center;
   }
}