/* Portfolio */
.portfolio--section {
   display: flex;
   max-width: 1500px;
   flex-direction: column;
   align-items: flex-start;
   gap: 3rem;
}

.portfolio--container--box {
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 100%;
}

.portfolio--container {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
}

.portfolio--section--container {
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 42.6px;
   width: 100%;
}

.portfolio--section--img {
   border-radius: 8px;
   width: 100%;
}

.portfolio--section--img>img {
   width: 100%;
   border-top-right-radius: 10.6px;
   border-top-left-radius: 10.6px;
}

.portfolio--section--card {
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 0.75rem;
   flex: 1 0 0;
   cursor: grab;
   border-radius: 10.6px;
   background: var(--card);
   border-bottom: 4px solid transparent;
   box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
   align-self: stretch;
   transition: transform 0.6s ease;
}

.portfolio--section--card:hover {
   transform: scale(1.1);
}

.portfolio--section--card--content {
   display: flex;
   padding: 0.25rem 1.5rem 1.5rem 1.5rem;
   flex-direction: column;
   align-items: flex-start;
   gap: 32px;
   align-self: stretch;
}

.portfolio--section--title {
   color: var(--heading-colour);
}

.portfolio--link {
   text-decoration: none;
   display: flex;
   align-items: center;
   gap: 0.25rem;
   border-bottom: 1.33px solid var(--primary);
   padding-bottom: 10.6px;
   font-weight: 600;
   cursor: pointer;
}


.portfolio--link:hover .link--button {
   transform: translateX(0.5rem);
}

.portfolio--section--skills {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   gap: 1rem;
}

.portfolio--skill {
   display: inline-block;
   padding: 12px 12px;
   font-size: 15px;
   font-style: normal;
   font-weight: 400;
   line-height: 8px;
   text-align: center;
   text-decoration: none;
   border-radius: 2px;
   -webkit-border-radius: 30px;
   -moz-border-radius: 7px;
   -ms-border-radius: 7px;
   -o-border-radius: 7px;
   color: var(--primary);
   background-color: var(--card);
   border: 1.4px solid var(--primary);
}

.styles-module_item-container__a8zaY {
   margin: 2rem 2rem 2rem 2rem;
}

.styles-module_item-provider__YgMwz {
   max-width: 100vw;
}

.carousel {
   display: flex;
   align-items: center;
   width: 100%;
}

.carousel div>button {
   cursor: pointer;
}

.carousel div>button[data-direction=left] {
   background: url(https://cdn.dsmcdn.com/web/production/slick-arrow.svg) 50% no-repeat;
   border: none;
   height: 80px;
   position: relative;
   transform: rotate(180deg);
   margin-right: 10px;
}

.carousel div>button[data-direction=right] {
   background: url(https://cdn.dsmcdn.com/web/production/slick-arrow.svg) 50% no-repeat;
   border: none;
   height: 80px;
   position: relative;
   margin-left: 10px;
}

@media only screen and (max-width: 1074px) {
   .portfolio--container--box {
      flex-direction: column;
      gap: 30px;
   }

   .portfolio--container {
      align-items: center;
   }
}

@media only screen and (max-width: 578px) {
   .portfolio--section--card {
      transition: none;
   }

   .portfolio--section--card:hover {
      transform: none;
   }

   .styles-module_item-container__a8zaY {
      margin: 1rem;
   }

   .portfolio--section--card--content {
      padding: 0.5rem;
   }
}