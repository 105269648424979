.navbar {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 15px 85.333px;
   background: var(--background);
   /* box-shadow: 0px 5.333px 80px 0 rgba(0, 0, 0, 0.1); */
   position: fixed;
   top: 0px;
   left: 0;
   right: 0;
   height: 60px;
}

.navbar--items>ul {
   list-style: none;
   display: flex;
   align-items: flex-start;
   gap: 42.667px;
   text-decoration: none;
}

.navbar--items>ul>li>a {
   text-decoration: none;
}

.navbar--content {
   color: var(--darkblue);
   font-size: 18px;
   font-style: normal;
   font-weight: 500;
   line-height: 24px;
   cursor: pointer;
}

.navbar--active--content {
   color: var(--primary);
}

/* Hamburger menu  */
.nav__hamburger {
   display: none;
   width: 1.875rem;
   height: 1.313rem;
   flex-direction: column;
   justify-content: space-around;
   top: 25px;
   right: 25px;
   cursor: pointer;
}

.nav__hamburger__line {
   display: block;
   height: 0.188rem;
   width: 100%;
   background-color: #000000;
   border-radius: 0.625rem;
   transition: all ease-in-out 0.2s;
}


@media screen and (max-width: 1200px) {
   .nav__hamburger {
      display: flex;
      z-index: 200;
   }

   .navbar--items {
      display: flex;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--background);
      top: -30rem;
      left: 0;
      width: 100%;
      transition: all ease-in-out 0.4s;
   }

   .navbar--items ul {
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 3rem 0 0.6rem;
      z-index: -100;
   }

   .navbar--items ul li {
      text-align: center;
   }

   .navbar--items ul li a {
      padding: 0.5rem;
   }

   .navbar--items {
      z-index: -1000;
   }

   .navbar--items.active {
      top: 30px;
   }

   .nav__hamburger.active :nth-child(1) {
      transform: rotate(45deg) translate(0.45rem, 0.1875rem);
   }

   .nav__hamburger.active :nth-child(2) {
      opacity: 0;
   }

   .nav__hamburger.active :nth-child(3) {
      transform: rotate(-45deg) translate(0.45rem, -0.1875rem);
   }
}