.scrollbar {
   background: var(--background);
}

.progress-bar {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   height: 10px;
   background: var(--primary);
   transform-origin: 0%;
}