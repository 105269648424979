/* Footer */
.footer--container {
   display: flex;
   padding: 106.667px 85.333px;
   flex-direction: column;
   align-items: flex-start;
   background: var(--primary);
   align-items: center;
   align-self: stretch;
   color: var(--background)
}

.footer--link--container {
   display: flex;
   justify-content: space-between;
   align-items: center;
   align-self: stretch;
}

.footer-title-item {
   cursor: pointer;
   font-size: 1.25rem;
}

.footer--items>ul {
   list-style: none;
   display: flex;
   align-items: flex-start;
   gap: 42.667px;
   text-decoration: none;
   cursor: pointer;
}

.footer--items ul>li>a {
   text-decoration: none;
}

.footer--social--icon>ul {
   list-style: none;
   display: flex;
   justify-content: flex-end;
   align-items: center;
   gap: 16px;
   flex-shrink: 0;
   text-decoration: none;
}

.divider {
   margin: 106px 0 42.67px;
   height: 1.333px;
   width: 100%;
   background: var(--background);
}

.footer--content--container {
   display: flex;
   justify-content: space-between;
   align-items: center;
   align-self: stretch;
}

.footer--content {
   color: var(--background);
   font-size: 18.667px;
   font-weight: 400;
   line-height: 28px;
}

@media only screen and (max-width: 928px) {
   .footer--content--container {
      display: flex;
      flex-direction: column;
   }

   .footer--link--container {
      flex-direction: column;
      justify-content: center;
   }

   .footer--items>ul {
      display: grid;
      padding-left: 0;
      text-align: center;
      padding-top: 3em;
      padding-bottom: 3rem;
   }

   .footer--social--icon {
      text-align: center;
   }

}