/* About */
.hero--section--content {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 21.333px;
}

.about--section {
   max-width: 1800px;
   display: grid;
   /* padding: 2.5rem; */
   align-items: center;
   gap: 4rem;
   grid-template-columns: repeat(2, 1fr);
}

.about--section--img {
   display: flex;
}

.about--section--description {
   font-size: var(--p-text);
}

.about--section--img>img {
   margin: auto;
   width: 100%;
   height: 100%;
   max-width: 488.57px;
   max-height: 650px;
   border: 3px solid;
}

@media screen and (max-width: 1100px) {
   .about--section {
      text-align: center;
      display: flex;
      flex-direction: column;
      padding-top: 0;
      padding-bottom: 0;
   }

   .about--section--img>img {
      max-width: 500px;
      max-height: 500px;
   }
}