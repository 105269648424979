.hero--container {
   row-gap: 30vh;
   max-width: 1500px;
}

.hero--content {
   grid-template-columns: 18.75rem repeat(2, 1fr);
   padding-top: 5vh;
   align-items: center;
   gap: 1.5rem;
}

.hero--social {
   display: grid;
   grid-template-columns: max-content;
   row-gap: 1rem;
   justify-self: center;
}

.hero--social--icon {
   font-size: var(--subtitle-font-size);
   color: var(--title-color);
}

.hero--social--icon:hover {
   color: var(--title-color-dark);
}

.hero--subtitle {
   position: relative;
   font-size: 1.25rem;
   padding-left: 5.4rem;
   font-weight: 400;
   margin-bottom: 1rem;
}

.hero--subtitle::before {
   content: '';
   position: absolute;
   width: 70px;
   height: 1px;
   background-color: var(--heading-colour);
   left: 0;
   top: 1rem;
}

.hero--social--icon .hero--social {
   display: grid;
   grid-template-columns: max-content;
   row-gap: 1rem;
}

.hero--img {
   background: url(../../../../public/img/hero_image4.jpg);
   background-repeat: no-repeat;
   background-position: center;
   background-size: cover;
   order: 1;
   justify-self: center;
   width: 300px;
   height: 300px;
   border: 3px solid;
   animation: profile--animate 8s ease-in-out infinite 1s;
}

.hero--scroll {
   margin: auto;
}

.wheel {
   animation: scroll 2s ease infinite;
}

.hero--scroll--name {
   color: var(--primary);
   font-weight: 500;
   margin-right: .25rem;
   margin-left: 5px;
}

.hero--scroll--arrow {
   font-size: 1.25rem;
   color: var(--primary);
}

@keyframes scroll {
   0% {
      transform: translateY(0);
   }

   30% {
      transform: translateY(3.75rem);
   }
}

@keyframes profile--animate {
   0% {
      border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
   }

   50% {
      border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
   }

   100% {
      border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
   }
}

/* BREAK POINTS */
@media screen and (max-width: 1200px) {
   .hero--subtitle {
      padding-left: 3.75rem;
      margin-bottom: 1rem;
   }

   .hero--subtitle::before {
      width: 42px;
      top: 0.8rem;
   }

   .hero--img {
      width: 250px;
      height: 250px;
      box-shadow: inset 0 0 0 8px rgb(225 225 225 / 30%);
   }

}

@media screen and (max-width: 1051px) {
   .hero--content {
      grid-template-columns: auto;
      grid-template-rows: repeat(3, 0.25rm);
      align-items: center;
      padding: 0px;
   }

   .hero--img {
      order: 1;
      box-shadow: inset 0 0 0 6px rgb(225 225 225 / 30%);
      width: 200px;
      height: 200px;
   }

   .hero--social {
      order: 2;
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 2rem;
   }

   .hero--data {
      order: 3;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
   }

   .hero--scroll {
      display: none;
   }
}