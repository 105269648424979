/* Global CSS */
body {
  margin: 0;
  font-family: "DM Sans", sans-serif;
  -webkit-font-smoothing: "DM Sans", sans-serif;
  -moz-osx-font-smoothing: "DM Sans", sans-serif;
  font-style: normal;
  background-color: var(--background);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300;9..40,400;9..40,500;9..40,600;9..40,700&display=swap');


:root {
  /* --primary: #323232; */
  --primary: #454545;
  --heading-colour: #282938;
  --bg-shade: #f5fcff;
  --github: #e62872;
  --darkblue: #1c1e53;
  --black: #000000;
  --white: #ffffff;
  --background: #DDD0C8;
  --card: #F0EDE5;

  --h1-hero-title: 56px;
  --p-text: 20px;

  --title-font-size: 3.5rem;
  --subtitle-font-size: 2rem;
  --section--subtitle: 1.3rem;
  --p-15: 1.5rem;
  --p-2: 2rem;
}

ul {
  margin: 0;
  padding: 0;
}

.btn {
  font-family: "DM Sans";
  display: inline-block;
  padding: 14px 32px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: background-color 0.5s, color 0.5s;
  -webkit-tranistion: background-color 0.5s;
  -moz-tranistion: background-color 0.5s;
  -ms-tranistion: background-color 0.5s;
  -o-tranistion: background-color 0.5s;
}

.btn-outline-primary {
  color: var(--primary);
  background-color: var(--background);
  border: 1px solid var(--primary);
  display: flex;
  gap: 16px;
}

.btn-outline-primary:hover {
  color: var(--white);
  background-color: var(--primary);
}

.btn-primary {
  color: var(--white);
  background-color: var(--primary);
  border: 1px solid var(--primary);
  display: flex;
  gap: 16px;
}

.btn-primary:hover {
  color: var(--primary);
  background-color: var(--background);
}

.btn-unavailable {
  color: var(--white);
  background-color: var(--primary);
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.6;
  border: none
}

.btn-github {
  color: var(--white);
  background-color: var(--github);
  border: var(--github);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: 0 1px 2px 0 rgba(16, 24, 40, 0.95);
  gap: 16px;
}

.btn-github:hover {
  color: var(--github);
  background-color: var(--white);
}

.link--button {
  font-size: 1.5rem;
  transition: 0.3s;
}

/* CSS CLASSES */
.section {
  height: 100vh;
}

.section--title {
  font-size: var(--title-font-size);
  margin-bottom: 0.25rem;
}

.section--subtitle {
  color: var(--heading-colour);
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

/* LAYOUT */
.container {
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30vh;
  padding-top: 20vh
}

.grid {
  display: grid;
}

/* Global CSS End */

h1 {
  font-size: 56px;
  font-weight: 700;
  line-height: 67px;
}

h2 {
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
}

h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
}

a {
  text-decoration: none;
}

input,
textarea {
  font-size: 1rem;
  resize: none;
}

.text-lg {
  color: var(--darkblue);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.text-mg {
  color: var(--darkblue);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.text-sm {
  color: var(--black);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.sub--title {
  color: var(--heading-colour);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

/* BUTTONS */
.button {
  display: inline-block;
  background-color: var(--title-color);
  color: var(--container-color);
  padding: 1.25rem 2rem;
  border-radius: 1rem;
  font-weight: var(--font-medium);
  cursor: pointer;
}

.button:hover {
  background-color: var(--title-color-dark);
}

.button--icon {
  margin-left: var(--mb-0-5);
}

.button--flex {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

/* BREAK POINTS */
@media screen and (max-width: 1600px) {
  .container {
    /* margin-left: min(1.5rem);
    margin-right: min(1.5rem); */
    padding-left: min(1.5rem);
    padding-right: min(1.5rem);
  }
}

@media screen and (max-width: 1051px) {
  .container {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }


}

@media screen and (max-width: 450px) {
  :root {
    --title-font-size: 2.5rem;
    --subtitle-font-size: 2rem;
    --section--subtitle: 1.3rem;
    --p-15: 1.5rem;
    --p-2: 2rem;
    --p-text: 1.1rem;
  }

  h1 {
    line-height: 50px;
  }
}