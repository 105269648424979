/* Contact */
.contact--section {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   text-align: center;
   gap: 64px;
}

.contact--grid {
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   gap: 8rem;
}

.contact--content {
   padding: 1.5rem 3.5rem 1.5rem 3.5rem;
   width: auto;
   border-radius: 10.6px;
   background: var(--card);
   box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
}

.contact--card--icon {
   font-size: 2.5rem;
}

.contact--card-title {
   font-size: var(--p-15);
}

.contact--card--data {
   display: block;
   margin-bottom: 2rem;
}

.contact--button {
   color: var(--text-color);
   font-size: var(--small-font-size);
   display: inline-flex;
   align-items: center;
   justify-content: center;
   column-gap: 0.25rem;
}


.contact--button:hover .link--button {
   transform: translateX(0.35rem);
}

.contact--form--container {
   display: grid;
   width: 100%;
   row-gap: 32px;
}

.contact--label {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 10.6px;
   flex: 1 0 0;
   width: 100%;
}

.contact--input {
   display: flex;
   font-family: "DM Sans", sans-serif;
   padding: 16px;
   align-items: center;
   align-self: stretch;
   border-radius: 8px;
   border: none;
   background: var(--card);
   box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);

}

.submit--button--section {
   margin-top: 10px;
   display: flex;
   justify-content: center;
}

@media only screen and (max-width: 883px) {
   .contact--grid {
      grid-template-columns: repeat(1, 1fr);
      align-items: center;

   }
}

@media only screen and (max-width: 392px) {
   .contact--content {
      padding: 1rem 2rem 1rem 2rem;

   }
}